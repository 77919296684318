import {lazy, Suspense, useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import {parseCookies} from 'nookies';
import routes from 'config/routes';
import Footer from 'components/common/Footer';
import CookieToast from 'components/common/CookieToast';

const Home = lazy(() => import('components/pages/Home/Home'));
const WhoAreWe = lazy(() => import('components/pages/WhoAreWe/WhoAreWe'));
const WhatWeDo = lazy(() => import('components/pages/WhatWeDo/WhatWeDo'));
const ContactUs = lazy(() => import('components/pages/ContactUs/ContactUs'));
const PrivacyPolicy = lazy(() => import('components/pages/PrivacyPolicy/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('components/pages/CookiePolicy/CookiePolicy'));

const App = () => {

    const [isVisibleCookiesToast, setIsVisibleCookiesToast] = useState<boolean>(false);

    useEffect(() => {

        const {consent} = parseCookies();
        let timeout: any;
        if (!consent) {

            timeout = setTimeout(() => {
                setIsVisibleCookiesToast(true);
            }, 3000);
        }
        
        return () => clearTimeout(timeout);

    }, []);

    const closeCookiesToast = () => setIsVisibleCookiesToast(false);

    return (
        <Suspense fallback={null}>
            <Routes>
                <Route path={routes.home} element={<Home/>}/>
                <Route path={routes.whatWeDo} element={<WhatWeDo/>}/>
                <Route path={routes.whoAreWe} element={<WhoAreWe/>}/>
                <Route path={routes.contactUs} element={<ContactUs/>}/>
                <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
                <Route path={routes.cookiePolicy} element={<CookiePolicy/>}/>
            </Routes>
            <Footer/>
            {isVisibleCookiesToast && (
                <CookieToast
                    closeCookiesToast={closeCookiesToast}
                />
            )}
        </Suspense>
    )
};

export default App;