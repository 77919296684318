/// <reference types="styled-jsx"/>
import {memo} from 'react';
import CustomRouter from 'components/common/CustomRouter';
import {createBrowserHistory} from 'history';
import App from './App';

const history = createBrowserHistory();

const Root = () => (
    <CustomRouter history={history}>
        <App/>
    </CustomRouter>
);

export default memo(Root);