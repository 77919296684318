exports.ONE_YEAR_COOKIE_MAX_AGE = 31536000 //60 * 60 * 24 * 365 - 1 year

exports.WHAT_WE_DO_TAB_NAMES = {
    IAAS: 1,
    PAAS: 2,
    SAAS: 3,
    FAAS: 4,
    CLOUD_STORAGE: 5,
    PROJECT_MANAGEMENT: 6,
    UX_UI_CONSULTING: 7,
    WEBSITE_DESIGN: 8,
    SECURITY_CONSULTING: 9,
    ANALYTICS_REPORTING: 10,
    SOLUTIONS: 11,
    DEVELOPMENT: 12,
    TESTING: 13,
    DEPLOYMENT: 14,
    MAINTENANCE: 15,
    ON_OFF: 16,
    KEYWORD: 17,
    CONTENT: 18,
    ANALYTICS: 19,
    INTERNATIONAL: 20,
    IT_DEVELOPMENT: 21,
    INFRASTRUCTURE: 22,
    HELP: 23,
    CONSULTING: 24,
    NETWORK: 25,
    MARKET: 26,
    VISION: 27,
    IDENTITY: 28,
    EVALUATION: 29,
    PROTECTION: 30,
    DIGITAL: 31,
    MARKETING_CONTENT: 32,
    CAMPAIGNS: 33,
    BRANDING: 34,
    COMMUNICATIONS: 35
};

exports.WHAT_WE_DO_SECTION_NAMES = {
    CLOUD: 'cloud',
    WEB: 'web',
    APP: 'app',
    SEO: 'seo',
    IT: 'it',
    BRAND: 'brand',
    MARKETING: 'marketing'
};

exports.HEADER_HEIGHT = 72;