import {Link} from 'react-router-dom';
import constants from 'assets/sass/constants.module.scss';
import routes from 'config/routes';

const Footer = () => (
    <footer className="Footer">
        <div className="container">
            <img 
                src="/images/logos/logo-horizontal-white.svg" 
                alt="logo"
                width="244"
                height="45"
            />
            <div className="vertical-dashed-border is-hidden-touch"/>
            <div className="horizontal-dashed-border is-hidden-desktop"/>
            <nav>
                <ul>
                    <li>
                        <Link 
                            to={routes.whoAreWe} 
                            className="link-underline"
                        >
                            Who are we
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to={routes.whatWeDo} 
                            className="link-underline"
                        >
                            What we do
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to={routes.contactUs} 
                            className="link-underline"
                        >
                            Contacts
                        </Link>
                    </li>
                     <li>
                        <Link 
                            to={routes.privacyPolicy} 
                            className="link-underline"
                        >
                            Privacy Policy
                        </Link>
                    </li>

                     <li>
                        <Link 
                            to={routes.cookiePolicy} 
                            className="link-underline"
                        >
                            Cookies
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="vertical-dashed-border is-hidden-touch"/>
            <div>
                © {new Date().getFullYear()} <span className='firn-name-full'/>
            </div>
        </div>
        <style jsx>{`
            .Footer {
                padding: 2rem 0;
                .container {
                    @media all and (min-width: ${constants.desktop}) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 2rem;
                    }
                    @media all and (max-width: ${constants.tabletMaxW}) {
                        text-align: center;
                    }
                    .vertical-dashed-border {
                        height: 2rem;
                        width: 1px;
                        border-right: 1px dashed ${constants.white};
                    }
                    .horizontal-dashed-border {
                        height: 1px;
                        width: 4rem;
                        border-bottom: 1px dashed ${constants.white};
                        margin: 1rem auto 1.5rem auto;
                    }
                    nav {
                        ul {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 1rem;
                            @media all and (max-width: ${constants.tabletMaxW}) {
                                flex-direction: column;
                            }
                        }
                    }
                }
            }    
        `}</style>
    </footer>
);

export default Footer;