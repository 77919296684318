import {useLayoutEffect, useState, useEffect} from 'react';
import {Router} from 'react-router-dom';

const CustomRouter = ({history, ...props}: any) => {
	
    const [state, setState] = useState({
		action: history.action,
		location: history.location
    });

	useEffect(() => {

		if (!state.location.hash) {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			}, 150);
		}
	
	}, [state]);
	
    useLayoutEffect(() => history.listen(setState), [history]);

    return (
		<Router
			{...props}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		/>
	);
};

export default CustomRouter;